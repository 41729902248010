/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@layer base {

    [type='text'], 
    [type='email'],
    [type='url'], 
    [type='password'],
    [type='number'], 
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple], 
    select
    {
        background-color: #FFFFFF;
        border: 1px solid #E3E5E5;
        border-radius: 6px;
        color:  black;
        height: 2.75rem;
        padding-top: .875rem;
        padding-bottom: .875rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.875rem;
        caret-color: #25ABC3;
        --tw-ring-color: #E3E5E5;
    }
     
    textarea{
        background-color: #FFFFFF;
        border: 1px solid #E3E5E5;
        border-radius: 6px;
        color:  black;
        padding-top: .875rem;
        padding-bottom: .875rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.875rem;
        caret-color: #25ABC3;
        --tw-ring-color: #E3E5E5;
  resize: none;
    }

    /* ... */

    [type='text']:focus, 
    [type='email']:focus,
    [type='url']:focus, 
    [type='password']:focus,
    [type='number']:focus, 
    [type='date']:focus,
    [type='datetime-local']:focus,
    [type='month']:focus,
    [type='search']:focus,
    [type='tel']:focus,
    [type='time']:focus,
    [type='week']:focus,
    [multiple]:focus, 
    textarea:focus,
    select:focus
    /* ... */ {
        border: 1px solid #E3E5E5;
        background-color: #25ABC30D;
        caret-color: #25ABC3;
        --tw-ring-color: #25ABC3;
;



    }
    /*  */
    /* [type='text']:invalid,  */
    /* [type='email']:invalid, */
    /* [type='url']:invalid,  */
    /* [type='password']:invalid, */
    /* [type='number']:invalid,  */
    /* [type='date']:invalid, */
    /* [type='datetime-local']:invalid, */
    /* [type='month']:invalid, */
    /* [type='search']:invalid, */
    /* [type='tel']:invalid, */
    /* [type='time']:invalid, */
    /* [type='week']:invalid, */
    /* [multiple]:invalid,  */
    /* textarea:invalid, */
    /* select:invalid */
    /*     color:  #F16063; */
    /*     border: 1px solid #F16063; */
    /* } */
    input[type="radio"]:checked + label img {
        display: block;
}

    input[type="radio"]:checked + label span {
        display: None;
}
[type='checkbox'], [type='radio']{
color: #25ABC3;
}
a {
   @apply text-arrowTop  
}

.react-select__input-custom input:focus{
  --tw-ring-color: none;


}
}

@layer components {
  .activeTab {
    border-bottom: 3px solid #F5841F;
    color: #2D3748;
    font-weight: 700;

  }
  /* ... */
}


@layer utilities {
  .placeholder-monetary::placeholder{
font-family: 'roboto';
font-style: normal;
font-weight: 800;
font-size: 22px;
line-height: 26px;
color: #777D85;
opacity: 0.2;
  }
}
