.avatar-comp {
    align-items: center;
    // background-color: #25abc31f;
    border-radius: 50%;
    display: flex;
    height: 46px;
    min-width: 46px;
    padding: 0 12px;
.mj {
    color: var(--pacific-blue2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    font-weight: 700;
    height: 24px;
    letter-spacing: 0;
    line-height: 24px;
    min-width: 22px;
    text-align: center;
    white-space: nowrap;
  }

  .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .roboto-bold-pacific-blue-16px {
    color: var(--pacific-blue2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
  }


 }
