.Toastify__toast-container--bottom-center {
  min-width: 600px;
  min-height: 48px;
  padding: 16px;
  bottom: 5rem;
}

.Toastify__toast-icon {
  width: unset;
}

.Toastify__close-button--light {
  color: white;
  opacity: 1;
  width: 20px;
  align-self: center;
}


.Toastify__toast-container--bottom-right {
  bottom: 8rem;
  right: 12rem;
}

.react-datepicker {
  background: #FFFFFF;
  border: 1px solid rgba(45, 55, 72, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 3px 16px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-width: 300px;

}


.react-datepicker.month_date_picker__ {
  border: none !important;
  box-shadow: none !important;
}

.react-datepicker__month-container {
  background-color: white;
}

.react-datepicker__header {
  background-color: white;
  border: 0;
  padding: 0px;
}
.react-datepicker-year-header,
.react-datepicker__current-month {
  padding: 18px 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #2D3748;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__navigation {
  margin-top: 15px
}

.react-datepicker__day-name {
  width: 40px;
  height: 40px;
}
.react-datepicker__day-names {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */

  text-align: center;

  color: #777D85;

  opacity: 0.5;
}

.react-datepicker__month-text {
  padding: 13px;
}

.react-datepicker__month-text--today {
  font-weight: normal;
}

.submit_btn:disabled {
    color: #6F7780
}


.react-datepicker__month-text--keyboard-selected:not(.react-datepicker__month--disabled),
.react-datepicker__day--keyboard-selected:not(.react-datepicker__month--disabled),
.react-datepicker__month--in-range:not(.react-datepicker__month--disabled) {
  background: #25ABC3 !important;
  color: white !important;
  border-radius: 50px;
}

.react-datepicker__quarter-text {
  padding: 20px;
  border-radius: 6px;
  background: #FFFFFF;
  font-weight: bold;
  width: 60px !important;
  height: 60px !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover {
  background: #25ABC3;
}

.react-datepicker__day--selected,
.react-datepicker__month--selected,
.react-datepicker__quarter--selected,
.react-datepicker__month--selected:hover,
.react-datepicker__day--selected:hover,
.react-datepicker__day--range-end,
.react-datepicker__day--range-end:hover,
.react-datepicker__quarter--selected:hover {
  background: #25ABC3;
  border-radius: 50px;
  color: white;
}

.react-datepicker__day,
.react-datepicker__quarter-text {
  width: 40px;
  height: 40px;
  margin: none;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  width: 100px;
}

.react-datepicker__month--disabled {
  color: #ccc !important;
  pointer-events: none;
  background: none !important;
}


.menu-is-active path {
  fill: #00ACC4
}

.error path {
  fill: #F44343
}
.warning path{
   stroke: #F5831F
}
