

@import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500|Poppins:700");
@import "../../variables.css";
.container-center-horizontal {
 display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%; 

  * {
    box-sizing: border-box;
  }

  .hidden,
  .hidden * {
    pointer-events: none;
    visibility: hidden;
  }

  .home-page-beta {
    align-items: center;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    height: 960px;
    width: 1440px;
  }

  .header {
    align-items: center;
    background-color: var(--white);
    box-shadow: 0px 4px 4px #0000000a;
    display: flex;
    min-width: 1440px;
    padding: 0 49.7px;
  }

  .right {
    align-items: center;
    display: flex;
    height: 85px;
    justify-content: flex-end;
    margin-left: 32px;
    min-width: 546px;
  }

  .button {
    align-items: flex-start;
    background-color: var(--pacific-blue2);
    border-radius: 6px;
    display: flex;
    height: 44px;
    min-width: 116px;
    padding: 9.5px 18px;
    cursor: pointer;
  }

  .add-2 {
    height: 24px;
    width: 24px;
  }

  .button-1 {
    align-self: center;
    letter-spacing: 0;
    margin-left: 6px;
    min-height: 16px;
    min-width: 45px;
  }

  .profile {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-left: 20px;
    min-width: 162px;
  }

  .name {
    height: 19px;
    letter-spacing: 0;
    margin-left: 9px;
    min-width: 74px;
  }

  .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .arrow-drop-down {
    height: 24px;
    margin-bottom: 1px;
    margin-left: 9px;
    width: 24px;
  }

  .avatar {
    align-items: center;
    background-color: #25abc31f;
    border-radius: 12px;
    display: flex;
    height: 46px;
    min-width: 46px;
    padding: 0 12px;
  }

  .mj {
    color: var(--pacific-blue2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    font-weight: 700;
    height: 24px;
    letter-spacing: 0;
    line-height: 24px;
    min-width: 22px;
    text-align: center;
    white-space: nowrap;
  }

  .logo {
    height: 40px;
    margin-bottom: 1px;
    width: 184px;
  }

  .left {
    align-items: center;
    display: flex;
    height: 85px;
    margin-left: 32px;
    min-width: 546px;
  }

  .text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: 41px;
    width: 153px;
  }

  .dream-soft-company {
    cursor: pointer;
    letter-spacing: 0;
    margin-top: 6px;
    min-height: 19px;
  }

  .company {
    color: #777d85;
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 400;
    letter-spacing: 0;
    min-height: 16px;
  }

  .arrow_drop_down {
    height: 16px;
    margin-left: 8px;
    width: 16px;
  }

  .cards {
    align-items: flex-end;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 775px;
    margin-top: 50px;
    overflow: hidden;
    width: 1240px;
  }

  .if-you-have-any-ques {
    align-self: center;
    color: transparent;
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    letter-spacing: 0;
    margin-right: 1.18px;
    margin-top: 50px;
    min-height: 17px;
    text-align: center;
    width: 476px;
  }

  .span0 {
    color: var(--outer-space2);
    font-weight: 400;
  }

  .span1 {
    color: var(--pacific-blue-5);
    font-weight: 400;
  }

  .span2 {
    color: var(--pacific-blue-5);
    font-weight: 500;
  }

  .flex-row {
    align-items: center;
    display: flex;
    min-width: 1176px;
  }

  .overlap-group {
    height: 688px;
    margin-left: 95px;
    position: relative;
    width: 537px;
  }

  .illustration {
    height: 402px;
    left: 0;
    position: absolute;
    top: 178px;
    width: 478px;
  }

  .intersect {
    height: 688px;
    left: 79px;
    position: absolute;
    top: 0;
    width: 458px;
  }

  .flex-col {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 46.1px;
    min-height: 278px;
    width: 544px;
  }

  .here-to-change-the-w {
    color: var(--outer-space2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 12px;
    min-height: 100px;
    opacity: 0.7;
    width: 544px;
  }

  .welcome-to-the-first {
    color: var(--outer-space2);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xxxl);
    font-weight: 700;
    letter-spacing: 0;
    margin-left: 8px;
    margin-top: 12px;
    min-height: 72px;
    width: 479px;
  }

  .buttons {
    align-items: flex-start;
    display: flex;
    margin-left: 8px;
    margin-top: 12px;
    min-width: 313px;
  }

  .button-2 {
    align-items: center;
    background-color: var(--pacific-blue2);
    border-radius: 6px;
    display: flex;
    height: 44px;
    min-width: 153px;
    padding: 0 20px;
    cursor: pointer;
  }

  .button-3 {
    letter-spacing: 0;
    min-height: 16px;
    min-width: 113px;
    cursor: pointer;
  }

  .button-4 {
    align-items: center;
    background-color: var(--white);
    border: 1px solid var(--pacific-blue2);
    border-radius: 6px;
    display: flex;
    height: 44px;
    margin-left: 10px;
    min-width: 150px;
    padding: 0 20px;
    cursor: pointer;
  }

  .button-5 {
    color: var(--pacific-blue2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-weight: 700;
    letter-spacing: 0;
    min-height: 16px;
    min-width: 110px;
  }

  .badgebase {
    align-items: center;
    background-color: #f5831f;
    border-radius: 6px;
    display: flex;
    height: 26px;
    margin-left: 8px;
    min-width: 74px;
    padding: 0 8px;
  }

  .badge {
    height: 16px;
    letter-spacing: 0;
    min-width: 58px;
  }




  /*global*/
  @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

  @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500|Poppins:700");

  .screen a {
    display: contents;
    text-decoration: none;
  }



  * {
    flex-shrink: 0;
    pointer-events: auto;
  }

  .overlay {
    display: none;
    height: 100%;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .overlay.animate-appear {
    animation: reveal 0.3s ease-in-out 1 normal forwards;
    display: block;
    opacity: 0;
  }

  .overlay.animate-disappear {
    animation: reveal 0.3s ease-in-out 1 reverse forwards;
    display: block;
    opacity: 1;
  }

  .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  * {
    box-sizing: border-box;
  }
  /*styleguid*/

  .roboto-bold-white-14px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
  }

  .roboto-medium-outer-space-16px {
    color: var(--outer-space2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
  }

  .roboto-normal-rolling-stone-14px-2 {
    color: var(--rolling-stone2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
  }

  .roboto-bold-pacific-blue-16px {
    color: var(--pacific-blue2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
  }

  .poppins-bold-outer-space-24px {
    color: var(--outer-space2);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
  }

  .roboto-medium-outer-space-14px {
    color: var(--outer-space2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 500;
  }

  .roboto-bold-pacific-blue-14px {
    color: var(--pacific-blue2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
  }

  .roboto-normal-outer-space-14px {
    color: var(--outer-space2);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
  }
.dropdown-menu-add-a-new-order {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  min-height: 118px;
  padding: 8px 0;
  width: 250px;
}

.hidden,
.hidden * {
  pointer-events: none;
  visibility: hidden;
}

.list-group-item {
  cursor: pointer;
  align-items: center;
  border-radius: 6px;
  display: flex;
  height: 48px;
  min-width: 250px;
  padding: 0 20px;
}

.add-document {
  height: 24px;
  width: 24px;
}

.text {
  height: 16px;
  letter-spacing: 0;
  margin-left: 12px;
  width: 174px;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list-group-item-1 {
  cursor: pointer;
  align-items: center;
  border-radius: 6px;
  display: flex;
  height: 48px;
  margin-top: 6px;
  min-width: 250px;
  padding: 0 20px;
}

.edit {
  height: 24px;
  width: 24px;
}

.dropdown-menu-user-menu {
  align-items: flex-start;
  display: flex;
  height: 960px;
  min-width: 1440px;
}

.hidden,
.hidden * {
  pointer-events: none;
  visibility: hidden;
}

.profile-menu{
.dropdown-menu-user-menu-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0px 3px 10px 
#0000001a;
  display: flex;
  flex-direction: column;
  min-height: 136px;
}

.list-group-itemdefault {
  align-items: center;
  align-self: center;
  background-color: var(--white);
  border-radius: 6px;
  display: flex;
  height: 54px;
  margin-top: 13px;
  min-width: 220px;
}

.avatar {
  align-items: center;
  background-color: 
#25abc31f;
  border-radius: 12px;
  display: flex;
  height: 46px;
  min-width: 46px;
  padding: 0 12px;
}

.mj {
  color: #00acc4;
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-weight: 700;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  min-width: 22px;
  text-align: center;
  white-space: nowrap;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-col-profile {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  min-height: 40px;
  margin-top: 0px;
}

.nameProfile {
  color: var(--outer-space2);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-weight: 700;
  letter-spacing: 0;
  min-height: 24px;
}

.yourmailcom {
  color: 
#777d85;
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0;
  min-height: 16px;
}

.line-1 {
  height: 1px;
  width: 220px;
}

.list-group-itemdefault-1 {
  align-items: center;
  display: flex;
  height: 30px;
  margin-left: 12px;
  margin-top: 24px;
  min-width: 190px;
  cursor: pointer;
}

.sign-out {
  align-items: flex-start;
  display: flex;
  height: 24px;
  width: 24px;
}

.overlap-group {
  height: 20px;
  position: relative;
  width: 20px;
}

.vector {
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

.union {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.text {
  color: var(--outer-space2);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-weight: 400;
  height: 16px;
  letter-spacing: 0;
  margin-left: 6px;
  min-width: 48px;
}
}
}
