:root {
  --black-2: 
rgba(0, 0, 0, 0.161);
  --black: 
rgba(0, 0, 0, 1);
  --resolution-blue: 
rgba(0, 36, 125, 1);
  --orient-2: 
rgba(0, 93, 133, 0.122);
  --orient: 
rgba(0, 93, 133, 1);
  --pacific-blue-52: 
rgba(0, 172, 196, 0.078);
  --pacific-blue-43: 
rgba(0, 172, 196, 0.102);
  --pacific-blue-32: 
rgba(0, 172, 196, 0.161);
  --pacific-blue2: 
rgba(0, 172, 196, 1);
  --pacific-blue-42: 
rgba(37, 171, 195, 0.102);
  --pacific-blue-22: 
rgba(37, 171, 195, 0.122);
  --pacific-blue-5: 
rgba(37, 171, 195, 1);
  --shark: 
rgba(39, 39, 46, 1);
  --outer-space-2: 
rgba(45, 55, 72, 0.102);
  --outer-space2: 
rgba(45, 55, 72, 1);
  --turquoise-pearl: 
rgba(51, 189, 208, 1);
  --victoria: 
rgba(65, 71, 155, 1);
  --emerald-2: 
rgba(72, 187, 120, 0.122);
  --emerald: 
rgba(72, 187, 120, 1);
  --rolling-stone-2: 
rgba(119, 125, 133, 0.161);
  --rolling-stone2: 
rgba(119, 125, 133, 1);
  --ghost: 
rgba(201, 206, 214, 1);
  --alto: 
rgba(222, 222, 222, 1);
  --mystic: 
rgba(226, 232, 240, 1);
  --mercury: 
rgba(227, 229, 229, 1);
  --gallery: 
rgba(235, 236, 240, 1);
  --orange-red-2: 
rgba(239, 45, 40, 0.122);
  --orange-red: 
rgba(239, 45, 40, 1);
  --seashell: 
rgba(241, 241, 241, 1);
  --orange-2: 
rgba(242, 99, 34, 0.122);
  --orange: 
rgba(242, 99, 34, 1);
  --red: 
rgba(244, 67, 67, 1);
  --tango-2: 
rgba(245, 131, 31, 0.122);
  --tango: 
rgba(245, 131, 31, 1);
  --zest-2: 
rgba(245, 132, 31, 0.122);
  --zest: 
rgba(245, 132, 31, 1);
  --black-haze: 
rgba(245, 246, 247, 1);
  --ghost-white: 
rgba(248, 249, 250, 1);
  --rose: 
rgba(255, 75, 85, 1);
  --white-2: 
rgba(255, 255, 255, 0.161);
  --white: 
rgba(255, 255, 255, 1);

  --font-size-xs: 12px;
  --font-size-s: 13px;
  --font-size-m: 14px;
  --font-size-l: 16px;
  --font-size-xl: 18px;
  --font-size-xxxl: 24px;
  --font-size-xxxxl: 36px;
  --font-size-xl2: 20px;
  --font-size-xl2: 22px;

  --font-family-inter: "Inter";
  --font-family-inter-bold: "Inter-Bold";
  --font-family-inter-regular: "Inter-Regular";
  --font-family-poppins: "Poppins";
  --font-family-poppins-bold: "Poppins-Bold";
  --font-family-poppins-medium: "Poppins-Medium";
  --font-family-poppins-semibold: "Poppins-SemiBold";
  --font-family-roboto: "Roboto";
  --font-family-roboto-medium: "Roboto-Medium";
  --font-family-roboto-regular: "Roboto-Regular";
  --font-family-roboto-semibold: "Roboto-SemiBold";
  --font-family-sf_pro_text-regular: "SF Pro Text-Regular";
}
