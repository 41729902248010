.date-picker-onfilter {
  display: block;
  width: 100%;
  min-width: 240px !important;
  box-shadow: none !important;
  border: none !important;


  .react-datepicker__header.react-datepicker-year-header, .react-datepicker__navigation {
    display: none;
  }
  .react-datepicker__month-text {
    font-size: 0.9rem;
  }
  .react-datepicker__quarter-text:hover   {
    border-radius: 50%;
  }


  .react-datepicker__quarter--selected:hover {
    background-color: #25ABC3 !important;

  }

  .react-datepicker__day--in-range:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end)  {
    border-radius: 50%;
    background-color: #cceef3  !important;
    color: black !important;
  }
  .react-datepicker__day {
    padding-top: 5px;
  }
}
.date-picker-onfilter-month {
  .react-datepicker__header.react-datepicker-year-header, .react-datepicker__navigation {
    display: flex !important;
  }
}
